import { useContext, useCallback } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router'
import { observer } from 'mobx-react-lite'

import { StoreContext } from '@/store'
import { getApiPath } from '@/store/helpers/fetch'
import { LAYOUT_TYPES } from '@/constants'

import { checkNodeLinks, getResults, getQuestionaryStatus } from '@/utils/helpers/questionary'

import Icon, { ICONS_TYPES, LABEL_POSITION_TYPES } from '@/components/Icon'
import Button, { BUTTON_TYPES } from '@/components/Button'
import NavBar from '@/components/NavBar'

import styles from './index.module.css'
import { checkResults } from '../../utils/helpers/questionary'

const Header = ({ className }) => {
  const store = useContext(StoreContext)
  const params = useParams()
  const navigate = useNavigate()

  const { authStore, layoutStore, formsStore } = store

  const logoIconType = layoutStore.props?.type === LAYOUT_TYPES.auth ||
    layoutStore.props?.type === LAYOUT_TYPES.operation ?
    ICONS_TYPES.logoWhite : ICONS_TYPES.logoMain

  const showProfileButton = layoutStore.props?.type === LAYOUT_TYPES.main ||
    layoutStore.props?.type === LAYOUT_TYPES.operation

  const quizFormName = 'editQuestionary' + params.id
  const quizFormObject = formsStore.getForm(quizFormName)
  
  const saveQuiz = useCallback(() => {
    const status = getQuestionaryStatus(
      quizFormObject.form.get('data.published_at'),
      quizFormObject.form.get('data.archived_at'),
    )
    const formBlocks = quizFormObject.form.get('data.blocks')
    const formResults = quizFormObject.form.get('data.results')

    const save = () => quizFormObject.form.submit(
      getApiPath('surveys/' + params.id),
      'PATCH',
      {
        transform: data => {
          const questions = data.blocks.reduce((memo, block, i) => {
            return [
              ...memo,
              {
                id: block.id,
                type: 'block',
                text: block.text,
                order: i + 1,
                next_id: block.next_id,
              },
              ...Object.values(block.questionary),
            ]
          }, [])
          const results = data.results || []
          return {
            title: data.title,
            questions: [...questions, ...results],
            published_at: data.published_at,
            archived_at: data.archived_at,
            operation_id: data.operation_id,
          }
        },
      }
    )
      .then(() => navigate('/admin/questionaries'))

    const errorBlock = checkNodeLinks(formBlocks)
    if (errorBlock !== undefined) {
      return formsStore.createForm('alertEdges', {
        modalComponent: 'AlertModal',
        props: {
          title: 'Ошибка',
          text: 'Вы пытаетесь сохранить анкету, которая содержит несвязные вопросы. ' +
            'Проверьте правильность составления анкеты и повторите попытку.',
        },
      })
    }

    if (status === 'draft') {
      save()
    } else {
      const newResults = getResults(formBlocks, formResults)
      const validResults = checkResults(newResults)
      if (!validResults) {
        return formsStore.createForm('alertResult', {
          modalComponent: 'AlertModal',
          props: {
            title: 'Ошибка',
            text: 'Вы пытаетесь опубликовать анкету, в которой заполнены не все результаты. ' +
              'Проверьте таблицу результатов и повторите попытку. Либо измените статус на "Черновик"',
          },
        })
      }
      formsStore.createForm('confirmPublishQuiz', {
        modalComponent: 'ConfirmModal',
        props: {
          title: 'Опубликовать анкету?',
          text: 'Вы собираетесь опубликовать анкету. ' +
            'После публикации ее изменение будет невозможно. ' +
            'Если вы уверены в своем действии, то нажмите кнопку “Подтвердить”. Либо измените статус на "Черновик"',
          onSuccess: save,
        },
      })
    }
  }, [formsStore, navigate, params.id, quizFormObject?.form])

  return (
    <div className={classNames(
      'container',
      styles.root,
      layoutStore.props?.type && styles[layoutStore.props.type],
      className
    )}>
      <Link to="/">
        <Icon className={styles.logo} type={logoIconType} />
      </Link>
      {layoutStore.props?.type === LAYOUT_TYPES.auth &&
        <div className={styles.authTitle}>ККМП. Контрсанкции</div>
      }
      {layoutStore.props?.type === LAYOUT_TYPES.profile &&
        <Icon
          label="Выход"
          className={styles.logout}
          type={ICONS_TYPES.logout}
          labelPosition={LABEL_POSITION_TYPES.left}
          onClick={() => authStore.logout(navigate)}
        />
      }
      {showProfileButton &&
        <Icon
          className={styles.profileButton}
          type={ICONS_TYPES.account}
          onClick={() => navigate('/profile')}
        />
      }
      {layoutStore.props?.type === LAYOUT_TYPES.quiz &&
        <>
          <div className={styles.title}>Конструктор анкеты</div>
          <div className={styles.quizButtons}>
            {!!quizFormObject && (
              <>
                <Button
                  type={BUTTON_TYPES.border}
                  onClick={() => {
                    navigate('/admin/questionaries')
                    formsStore.removeForm(quizFormName)
                  }}
                >
                  Закрыть
                </Button>
                <Button
                  type={BUTTON_TYPES.fill}
                  onClick={saveQuiz}
                >
                  Сохранить
                </Button>
              </>
            )}
          </div>
        </>
      }
      {layoutStore.props?.type === LAYOUT_TYPES.user &&
        <>
          <NavBar />
          <Link
            className={styles.profileBlock}
            to="/profile"
          >
            <Icon
              className={styles.profileButton}
              type={ICONS_TYPES.account}
              onClick={() => navigate('/profile')}
            />
            <div className={styles.profileInfo}>
              <div className={styles.username}>{authStore.user?.username}</div>
              <div className={styles.role}>{authStore.user?.role}</div>
            </div>
          </Link>
        </>
      }
    </div>
  )
}

export default observer(Header)
