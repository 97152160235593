import { Outlet } from 'react-router'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import classNames from 'classnames'

import Header from '@/components/Header'

import { StoreContext } from '@/store'
import { LAYOUT_TYPES } from '@/constants'

import styles from './index.module.css'

const Root = () => {
  const store = useContext(StoreContext)

  const { layoutStore } = store

  const isShowHeader = layoutStore.props?.type !== LAYOUT_TYPES.auth ||
    layoutStore?.props?.type !== LAYOUT_TYPES.operation

  return (
    <div className={classNames(
      styles.root,
      layoutStore.props?.type && styles[layoutStore.props.type]
    )}>
      {isShowHeader && <Header className={styles.header} />}
      <div className={classNames(styles.main, 'container')}>
        <Outlet/>
      </div>
    </div>
  )
}

export default observer(Root)
