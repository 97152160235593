import { useContext } from 'react'
import { toJS } from 'mobx'

import Icon, { ICONS_TYPES } from '@/components/Icon'

import { StoreContext } from '@/store'
import { getApiPath } from '@/store/helpers/fetch'

import { toPhone } from '@/utils/helpers/format'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'

const Profile = () => {
  const store = useContext(StoreContext)
  const { formsStore, httpStore, authStore } = store

  const user = authStore.user
  const modalComponent = user?.role === 'client' ? 'ClientEditModal' : 'UserEditModal'

  const usersEndpoint = 'users/' + user.id
  const dataEndpoint = getApiPath(usersEndpoint)

  const infoData = [
    { title: 'Название', info: user?.clients?.[0]?.title },
    { title: 'Вид деятельности компании', info: user?.clients?.[0]?.industry },
    { title: 'Имя', info: user?.fullname },
    { title: 'E-mail', info: user?.username },
    { title: 'Телефон', info: toPhone(user.clients[0]?.phone) },
    { title: 'Дополнительный E-mail', info: user.clients[0]?.additional_emails[0] },
  ]

  return (
    <div className={styles.root}>
      <h1 className={pageStyles.title}>Личный кабинет</h1>
      <div className={styles.content}>
        <div className={styles.block}>
          <Icon type={ICONS_TYPES.edit} className={styles.edit}
            onClick={() => {
              const formName = 'editUser' + user.id
              formsStore.createForm(formName, {
                modalComponent,
                form: {
                  data: {
                    ...toJS(user),
                    client: (toJS(user)?.clients || [])[0],
                  },
                },
                props: {
                  onSuccess: () => httpStore.fetchRequest(dataEndpoint, {
                    cacheTime: 0,
                  }),
                },
              })
            }}
          />
          {infoData.filter(({ info }) => info).map(({ title, info }, i) => (
            <div className={styles.dataRow} key={i}>
              <div className={styles.dataTitle}>{title}</div>
              <div className={styles.dataInfo}>{info}</div>
            </div>
          ))}
        </div>
      </div>
    </div>

  )
}

export default Profile
