/* eslint-disable max-len */
import React from 'react'

const SendEmail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={19}
    viewBox="0 0 26 19"
    {...props}
  >
    <path d="M1.5 1.5L12.4056 9.56067C12.7589 9.82177 13.2411 9.82177 13.5944 9.56067L24.5 1.5" stroke="#314560" strokeWidth="1.4" strokeLinecap="round"/>
    <path d="M25 5V2C25 1.44772 24.5523 1 24 1H2C1.44772 1 1 1.44772 1 2V17C1 17.5523 1.44772 18 2 18H13.5" stroke="#314560" strokeWidth="1.4" strokeLinecap="round"/>
    <path d="M22 9V18M22 9L20 12M22 9L24 12" stroke="#314560" strokeWidth="1.4" strokeLinecap="round"/>
  </svg>
)

export default SendEmail
